export const ENTRIES_PER_PAGE_DASHBOARD = 10
export const isDevelopment = process.env.NODE_ENV === 'development'
export const TOKEN = 'token'
export const REFRESH_TOKEN = 'refreshToken'
export const adminRole = 'admin'
export const affiliateRole = 'affiliate'
export const superAdminRole = 'superadmin'
export const financeRole = 'finance'
export const gamblingGroup = 'Gambling'
export const budgetGroup = 'BUDGET'
export const pbGroup = 'BILL'
export const offersStatus = {
  PAUSE: 'PAUSE',
  IN_TEST: 'IN_TEST',
  NOT_GO: 'NOT_GO',
  NO_CAP: 'NO_CAP',
  FLOW: 'FLOW',
}

export const slicesGroup = 'Slices'

export const periodOffersCap = {
  DAY: 'DAY',
  MONTH: 'MONTH',
  NO_PERIOD: 'NO_PERIOD',
}

export const offersModel = {
  CPA: 'CPA',
  CPL: 'CPL',
}

export const sourceNameings = {
  FB: 'FB',
  UAC: 'UAC',
  PPC: 'PPC',
  ALL: 'All',
}

export const compareOptions = 'compare'
export const allOptions = 'All'

export const countriesAndLanguages = [
  'Afghanistan (Pashto)',
  'Afghanistan (Dari)',
  'Albania (Albanian)',
  'Algeria (Arabic)',
  'Andorra (Catalan)',
  'Angola (Portuguese)',
  'Antigua and Barbuda (English)',
  'Argentina (Spanish)',
  'Armenia (Armenian)',
  'Australia (English)',
  'Austria (German)',
  'Azerbaijan (Azerbaijani)',
  'Bahamas (English)',
  'Bahrain (Arabic)',
  'Bangladesh (Bengali)',
  'Barbados (English)',
  'Belarus (Belarusian)',
  'Belarus (Russian)',
  'Belgium (Dutch)',
  'Belgium (French)',
  'Belgium (German)',
  'Belize (English)',
  'Benin (French)',
  'Bhutan (Dzongkha)',
  'Bolivia (Spanish)',
  'Bosnia and Herzegovina (Bosnian)',
  'Bosnia and Herzegovina (Croatian)',
  'Bosnia and Herzegovina (Serbian)',
  'Botswana (English)',
  'Brazil (Portuguese)',
  'Brunei (Malay)',
  'Bulgaria (Bulgarian)',
  'Burkina Faso (French)',
  'Burundi (Kirundi)',
  'Burundi (French)',
  'Cabo Verde (Portuguese)',
  'Cambodia (Khmer)',
  'Cameroon (French)',
  'Canada (English)',
  'Canada (French)',
  'Central African Republic (French)',
  'Chad (French)',
  'Chile (Spanish)',
  'China (Mandarin)',
  'Colombia (Spanish)',
  'Comoros (Comorian)',
  'Comoros (Arabic)',
  'Comoros (French)',
  'Congo, Democratic Republic of the (French)',
  'Congo, Republic of the (French)',
  'Costa Rica (Spanish)',
  'Croatia (Croatian)',
  'Cuba (Spanish)',
  'Cyprus (Greek)',
  'Cyprus (Turkish)',
  'Czech Republic (Czech)',
  'Denmark (Danish)',
  'Djibouti (French)',
  'Djibouti (Arabic)',
  'Dominica (English)',
  'Dominican Republic (Spanish)',
  'East Timor (Tetum)',
  'East Timor (Portuguese)',
  'Ecuador (Spanish)',
  'Egypt (Arabic)',
  'El Salvador (Spanish)',
  'Equatorial Guinea (Spanish)',
  'Equatorial Guinea (French)',
  'Eritrea (Tigrinya)',
  'Eritrea (Arabic)',
  'Estonia (Estonian)',
  'Eswatini (Swati)',
  'Eswatini (English)',
  'Ethiopia (Amharic)',
  'Fiji (English)',
  'Fiji (Fijian)',
  'Finland (Finnish)',
  'France (French)',
  'Gabon (French)',
  'Gambia (English)',
  'Georgia (Georgian)',
  'Germany (German)',
  'Ghana (English)',
  'Greece (Greek)',
  'Grenada (English)',
  'Guatemala (Spanish)',
  'Guinea (French)',
  'Guinea-Bissau (Portuguese)',
  'Guyana (English)',
  'Haiti (French)',
  'Honduras (Spanish)',
  'Hungary (Hungarian)',
  'Iceland (Icelandic)',
  'India (Hindi)',
  'India (English)',
  'India (Bengali)',
  'India (Telugu)',
  'India (Marathi)',
  'India (Tamil)',
  'India (Urdu)',
  'India (Gujarati)',
  'India (Kannada)',
  'India (Odia)',
  'India (Malayalam)',
  'India (Punjabi)',
  'India (Assamese)',
  'India (Maithili)',
  'India (others)',
  'Indonesia (Indonesian)',
  'Iran (Persian)',
  'Iraq (Arabic)',
  'Ireland (English)',
  'Ireland (Irish)',
  'Israel (Hebrew)',
  'Italy (Italian)',
  'Jamaica (English)',
  'Japan (Japanese)',
  'Jordan (Arabic)',
  'Kazakhstan (Kazakh)',
  'Kazakhstan (Russian)',
  'Kenya (Swahili)',
  'Kenya (English)',
  'Kiribati (English)',
  'Kiribati (Gilbertese)',
  'Korea, North (Korean)',
  'Korea, South (Korean)',
  'Kosovo (Albanian)',
  'Kosovo (Serbian)',
  'Kuwait (Arabic)',
  'Kyrgyzstan (Kyrgyz)',
  'Kyrgyzstan (Russian)',
  'Laos (Lao)',
  'Latvia (Latvian)',
  'Lebanon (Arabic)',
  'Lesotho (English)',
  'Lesotho (Sesotho)',
  'Liberia (English)',
  'Libya (Arabic)',
  'Liechtenstein (German)',
  'Lithuania (Lithuanian)',
  'Luxembourg (Luxembourgish)',
  'Luxembourg (French)',
  'Luxembourg (German)',
  'Madagascar (Malagasy)',
  'Malawi (Chewa)',
  'Malawi (English)',
  'Malaysia (Malay)',
  'Maldives (Dhivehi)',
  'Mali (French)',
  'Malta (Maltese)',
  'Marshall Islands (English)',
  'Marshall Islands (Marshallese)',
  'Mauritania (Arabic)',
  'Mauritania (French)',
  'Mauritius (English)',
  'Mauritius (French)',
  'Mexico (Spanish)',
  'Micronesia (English)',
  'Moldova (Moldovan)',
  'Moldova (Russian)',
  'Moldova (Romanian)',
  'Monaco (French)',
  'Mongolia (Mongolian)',
  'Montenegro (Serbian)',
  'Montenegro (Montenegrin)',
  'Morocco (Arabic)',
  'Morocco (French)',
  'Mozambique (Portuguese)',
  'Myanmar (Burmese)',
  'Namibia (English)',
  'Nauru (English)',
  'Nauru (Nauruan)',
  'Nepal (Nepali)',
  'Netherlands (Dutch)',
  'New Zealand (English)',
  'New Zealand (Māori)',
  'Nicaragua (Spanish)',
  'Niger (French)',
  'Nigeria (English)',
  'North Macedonia (Macedonian)',
  'Norway (Norwegian)',
  'Oman (Arabic)',
  'Pakistan (Urdu)',
  'Pakistan (English)',
  'Palau (Palauan)',
  'Palestine (Arabic)',
  'Panama (Spanish)',
  'Papua New Guinea (English)',
  'Papua New Guinea (Tok Pisin)',
  'Paraguay (Spanish)',
  'Paraguay (Guarani)',
  'Peru (Spanish)',
  'Philippines (Filipino)',
  'Philippines (English)',
  'Poland (Polish)',
  'Portugal (Portuguese)',
  'Qatar (Arabic)',
  'Romania (Romanian)',
  'Russia (Russian)',
  'Rwanda (Kinyarwanda)',
  'Rwanda (French)',
  'Saint Kitts and Nevis (English)',
  'Saint Lucia (English)',
  'Saint Vincent and the Grenadines (English)',
  'Samoa (Samoan)',
  'San Marino (Italian)',
  'Sao Tome and Principe (Portuguese)',
  'Saudi Arabia (Arabic)',
  'Senegal (French)',
  'Serbia (Serbian)',
  'Seychelles (Seychellois Creole)',
  'Seychelles (French)',
  'Seychelles (English)',
  'Sierra Leone (English)',
  'Singapore (English)',
  'Singapore (Malay)',
  'Singapore (Mandarin)',
  'Singapore (Tamil)',
  'Slovakia (Slovak)',
  'Slovenia (Slovene)',
  'Solomon Islands (English)',
  'Somalia (Somali)',
  'Somalia (Arabic)',
  'South Africa (English)',
  'South Africa (Zulu)',
  'South Africa (Xhosa)',
  'South Africa (Afrikaans)',
  'South Sudan (English)',
  'Spain (Spanish)',
  'Sri Lanka (Sinhala)',
  'Sri Lanka (Tamil)',
  'Sudan (Arabic)',
  'Suriname (Dutch)',
  'Sweden (Swedish)',
  'Switzerland (German)',
  'Switzerland (French)',
  'Switzerland (Italian)',
  'Syria (Arabic)',
  'Taiwan (Mandarin)',
  'Tajikistan (Tajik)',
  'Tajikistan (Russian)',
  'Tanzania (Swahili)',
  'Tanzania (English)',
  'Thailand (Thai)',
  'Togo (French)',
  'Tonga (Tongan)',
  'Tonga (English)',
  'Trinidad and Tobago (English)',
  'Tunisia (Arabic)',
  'Turkey (Turkish)',
  'Turkmenistan (Turkmen)',
  'Tuvalu (Tuvaluan)',
  'Tuvalu (English)',
  'Uganda (English)',
  'Uganda (Swahili)',
  'Ukraine (Ukrainian)',
  'United Arab Emirates (Arabic)',
  'United Kingdom (English)',
  'United States (English)',
  'Uruguay (Spanish)',
  'Uzbekistan (Uzbek)',
  'Vanuatu (Bislama)',
  'Vanuatu (English)',
  'Vatican City (Latin)',
  'Vatican City (Italian)',
  'Venezuela (Spanish)',
  'Vietnam (Vietnamese)',
  'Yemen (Arabic)',
  'Zambia (English)',
  'Zimbabwe (English)',
]
